footer {
  padding: 80px 60px;
  padding-bottom: 0;
  background-color: #333333;
}
.footer_1 {
  display: flex;
  justify-content: space-between;
}
.footer_logo img {
  width: 300px;
}
.footer_social a {
  margin-right: 20px;
}
.footer_1_2 {
  display: flex;
  justify-content: flex-start;
  color: white;
}
.footer_1_2_links {
  padding-left: 60px;
}
.footer_1_2 h6 {
  font-size: 14px;
  margin-bottom: 15px;
  font-weight: 600;
}
.footer_1_2 a {
  display: block;
  text-transform: uppercase;
  margin: 15px 0;
  font-size: 14px;
}
.footer_2 {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  text-align: center;
  padding: 10px;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
@media only screen and (max-width: 768px) {
  footer {
    padding: 70px 40px;
    padding-bottom: 0;
  }
  .footer_logo img {
    width: 220px;
  }
  .footer_1_2_links {
    padding-left: 40px;
  }
}
@media only screen and (max-width: 650px) {
  .footer_1 {
    flex-wrap: wrap;
  }
  .footer_1_1 {
    flex: 0 0 100%;
    margin-bottom: 30px;
  }
  .footer_1_2_links {
    padding-right: 40px;
    padding-left: 0;
  }
  .footer_1_2 h6 {
    font-size: 12px;
  }
  .footer_1_2 a {
    font-size: 12px;
  }
  .footer_2 {
    font-size: 14px;
  }
}
@media only screen and (max-width: 600px) {
  footer {
    padding: 60px 20px;
    padding-bottom: 0;
  }
}

/* ScrollToTopButton.css */
.scroll-to-top-btn {
  position: sticky; /* Make it sticky */
  bottom: 20px;
  right: 20px;
  background-color: #ff156d; /* Customize your color */
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  padding: 15px;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s, transform 0.3s;
  opacity: 0.7;
  transform: translateY(0); /* Keeps the button visible in its place */
}

.scroll-to-top-btn:hover {
  opacity: 1;
  transform: translateY(-3px); /* Slightly lift on hover */
}

.scroll-to-top-btn:focus {
  outline: none;
}
