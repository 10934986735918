.newsletter_section {
  background-color: #FCCB2F;
  text-align:center;
  padding: 60px 0;
  background-image: url(./../../assets/img/index/newsletter_bg.png);
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
}
.newsletter_section h3 {
  font-size: 60px;
  color: #EC1A6C;
  font-weight: 800;
  font-family: 'Work Sans', sans-serif;
  letter-spacing: 2px;
  margin-bottom: 45px;
}
.newsletter_section form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.newsletter_section form input {
  width: 100%;
  max-width: 550px;
  display: block;
  padding: 15px 20px;
  border: none;
  border-radius: 10px;
  margin-bottom: 35px;
}
.newsletter_section form input::placeholder {
  font-family: 'Roboto', sans-serif;
  color: rgba(0, 0, 0, 0.45);
}
.newsletter_section form button {
  width: 220px;
  padding: 20px 0;
  font-size: 20px;
  font-family: 'Roboto',sans-serif;
  font-weight: 600;
  background-color: #EC1A6C;
  letter-spacing: 1px;
  color: #FCCB2F;
}
@media only screen and (max-width: 800px) {
  .newsletter_section{
    background-image: url(./../../assets/img/full50.png);
    background-position:-200px 150px ;
  }
}
@media only screen and (max-width: 800px) {
    .newsletter_section h3 {
      font-size: 48px;
      letter-spacing: 0px;
    }
}
@media only screen and (max-width: 768px) {
    .newsletter_section {
      padding: 60px 40px;
    }
}
@media only screen and (max-width: 600px) {
    .newsletter_section {
      padding: 50px 20px;
    }
    .newsletter_section h3 {
      font-size: 36px;
      margin-bottom: 25px;
    }
    .newsletter_section form button {
      font-size: 18px;
      padding: 15px 0;
      width: 180px;
    }

}
