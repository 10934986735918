.el .second p {
  color: #777;
  font-size: 22px;
  line-height: 40px;
  margin: 20px auto 30px;
  max-width: 880px;
  text-align: center;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .el .second p {
    font-size: 16px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .el .second p {
    font-size: 20px;
    line-height: 35px;
  }
}

main {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

h1 {
  color: #c84b31; /* Example vibrant color */
}

.highlight {
  font-weight: bold;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 0.5rem;
}

/* Full-screen overlay */
.form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 1000; /* Ensure it appears above other content */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 20px; /* Padding to prevent content from sticking to edges */
  scrollbar-width: thin;
}

/* Form container styling */
.form-container {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  max-height: 90vh; /* Limit height to 90% of viewport */
  overflow-y: auto; /* Scroll inside form container if content exceeds height */
}

/* Close button */
.contact .form_container .close-button {
  position: absolute;
  top: 30px;
  right: 30px;
  border: none;
  background-color: #ec1a6c;
  color: white;
  cursor: pointer;
  border-radius: 20px;
  padding: 10px;
  min-width: 30px;
  border: 1px solid #ec1a6c;
}

/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Dark background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content */
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
  position: relative;
  z-index: 1001;
}

/* Close button */
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  /* Close button */
  .contact .form_container .close-button {
    position: absolute;
    top: 35px;
    right: 35px;
    border: none;
    background-color: #ec1a6c;
    color: white;
    cursor: pointer;
    border-radius: 15px;
    padding: 0px;
    min-width: 35px;
    border: 1px solid #ec1a6c;
  }
}

@media only screen and (max-width: 600px) {
  /* Close button */
  .contact .form_container .close-button {
    position: absolute;
    top: 35px;
    right: 35px;
    border: none;
    background-color: #ec1a6c;
    color: white;
    cursor: pointer;
    border-radius: 15px;
    padding: 0px;
    min-width: 35px;
    border: 1px solid #ec1a6c;
  }
}

.el .first {
  padding: 150px 0;
  text-align: center;
  color: white;
  font-size: 48px;
  background-color: rgba(236, 26, 108, 0.45);
  background-position: center;
  background-size: cover;
  background-blend-mode: overlay;
}
#battersea {
  background-image: url(./../../assets/img/location/battersea.png);
}
#canningtown {
  background-image: url(./../../assets/img/location/canningtown.png);
}
#hernehill {
  background-image: url(./../../assets/img/location/hernehill1.png);
}
#camden {
  background-image: url(./../../assets/img/location/camden.png);
}
#croydon {
  background-image: url(./../../assets/img/location/croydon.png);
}
@media only screen and (max-width: 1000px) {
  .el .first {
    font-size: 40px;
  }
}
@media only screen and (max-width: 768px) {
  .el .first {
    font-size: 36px;
  }
}
@media only screen and (max-width: 600px) {
  .el .first {
    font-size: 28px;
    padding: 120px 0;
  }
}

.el .second {
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo-and-heading {
  display: flex;
  align-items: center;
  gap: 30px; /* Space between the logo and heading */
}
.logo {
  width: 130px; /* Adjust the size of the logo as needed */
  height: auto;
}

.heading {
  font-size: 24px;
  color: #ff156d;
}
@media (max-width: 768px) {
  .logo-and-heading {
    flex-direction: column; /* Stack the logo and heading vertically */
  }

  .logo {
    order: 1; /* Logo comes first on smaller screens */
  }

  .heading {
    order: 2; /* Heading comes after the logo */
    font-size: 20px; /* Optionally reduce font size for smaller screens */
  }
}
.el .second2 {
  padding: 20px;
}

.el .second h2 {
  font-size: 48px;
  text-align: center;
}
.el .four {
  padding: 60px;
}
.el .four h2 {
  font-size: 40px;
  text-align: center;
}
.el .links {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  font-size: 24px;
  font-weight: 600;
  flex-wrap: wrap;
}
.el .links a {
  padding: 35px 20px;
  min-width: 300px;
  background-color: #fccc2d;
  text-align: center;
  color: #ec1a6c;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.el .links a img {
  height: 50px;
  width: auto;
  margin-left: 15px;
}
.el .links2 {
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
  flex-wrap: wrap;
}
.el .links2 button {
  padding: 35px 20px;
  min-width: 300px;
  background-color: #fccc2d;
  text-align: center;
  font-size: 22px;
  cursor: pointer;
  font-weight: 600;
  color: #ec1a6c;
  border-radius: 10px;
  margin: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 1000px) {
  .el .second h2 {
    font-size: 40px;
  }
  .el .links a {
    max-width: none !important;
    flex-grow: 1;
  }
  .el .links2 button {
    max-width: none !important;
    flex-grow: 1;
  }
}
@media only screen and (max-width: 768px) {
  .el .second {
    padding: 50px 40px;
  }
  .el .second2 {
    padding: 0px 15px 10px;
  }
  .el .second h2 {
    font-size: 36px;
  }
  .el .links a {
    padding: 28px 0;
    font-size: 20px;
  }
  .el .links2 button {
    padding: 16px 0;
    font-size: 20px;
  }
}
@media only screen and (max-width: 600px) {
  .el .second {
    padding: 40px 20px;
  }
  .el .second {
    padding: 30px 17px;
  }
  .el .second h2 {
    font-size: 28px;
  }
  .el .links {
    flex-wrap: wrap;
    margin-top: 40px;
  }
  .el .links a {
    flex: 0 0 100%;
    max-width: none !important;
    margin: 10px 0;
    padding: 20px 0;
    font-size: 18px;
  }
  .el .links2 {
    flex-wrap: wrap;
  }
  .el .links2 button {
    flex: 0 0 100%;
    max-width: none !important;
    padding: 25px 0;
    font-size: 18px;
  }
}

.el .third {
  width: 100%;
  display: grid;
  grid-template-columns: 55% 45%;
  padding: 30px;
  padding-top: 0;
  background-image: url(./../../assets/img/location/locationbg.png);
  background-repeat: no-repeat;
  background-position: 130% -50%;
}
.el .third2 {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 30px;
  padding-top: 20px;
  background-image: url(./../../assets/img/location/locationbg.png);
  background-repeat: no-repeat;
  background-position: 130% -50%;
}

.el .third_2 h2 {
  text-align: center;
}
.el .third_1 iframe {
  width: 100%;
}
.el .third_1 img {
  width: 100%;
  max-width: 100%;
  height: auto; /* Ensures image scales proportionally */
  border-radius: 10px;
}
.el .third_12 img {
  width: 100%;
  max-width: 100%;
  height: 650px; /* Ensures image scales proportionally */
  border-radius: 10px;
}
.el .third_2 {
  padding: 0px 60px;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}
.el .third_2 h4 {
  font-size: 36px;
  margin-bottom: 10px;
}
.el .third_2 p {
  margin-top: 20px;
  margin-bottom: 20px;
  text-indent: 75px;
  font-size: 20px;
}
.el .third_2 ul li {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 20px;
  text-align: center;
}
.el .third_2 span {
  font-size: 20px;
  line-height: 35px;
  color: #777777;
}
.el .contact_us h4 {
  color: #ec1a6c;
}
.contact_us {
  padding: 20px;
}
@media only screen and (max-width: 1000px) {
  .el .third {
    grid-template-columns: 100%;
  }
  .el .third2 {
    grid-template-columns: 100%;
  }
  .el .third_2 {
    padding-left: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .el .third_2 div {
    margin: 0px;
    text-align: center;
  }
}
@media only screen and (max-width: 768px) {
  .el .third {
    padding: 60px 40px;
    padding-top: 0;
  }
  .el .third2 {
    padding: 60px 40px;
    padding-top: 0;
    grid-template-columns: 1fr; /* Single column layout for smaller screens */
    grid-template-rows: auto auto; /* Adjust row heights for smaller screens */
  }
  .el .third_2 {
    padding-top: 30px;
    order: 2;
  }
  .el .third_12 {
    order: 1; /* Ensure image comes first */
  }
}
@media only screen and (max-width: 600px) {
  .el .third {
    padding: 40px 20px;
    padding-top: 0;
  }
  .el .third2 {
    padding: 40px 20px;
    padding-top: 0;
    grid-template-columns: 1fr; /* Single column layout for smaller screens */
    grid-template-rows: auto auto; /* Adjust row heights for smaller screens */
  }
  .el .third_2 {
    order: 2;
  }
  .el .third_12 {
    order: 1; /* Ensure image comes first */
  }
  .el .third_2 h4 {
    font-size: 28px;
  }
  .el .third_2 span {
    font-size: 16px;
    line-height: 30px;
  }
}

/* New */
.tiffinclub-container {
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
}

.intro-section {
  text-align: center;
  margin-bottom: 40px;
}

.logo-and-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.logo {
  width: 130px;
}

.heading {
  font-size: 24px;
  color: #ff156d;
}

.intro-text {
  font-size: 22px;
  color: #777;
  max-width: 880px;
  margin: 20px auto;
}

.content-block {
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
  align-items: center;
}

.content-image {
  width: 50%;
  border-radius: 8px;
  object-fit: cover;
}

.content-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content-text h2 {
  font-size: 35px;
  margin-bottom: 20px;
  text-align: center;
  color: #ff156d;
}

.content-text p {
  font-size: 22px;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
  text-indent: 35px;
}

.card-container {
  background-color: #fec722; /* Yellow background */
  padding: 66px; /* Padding inside the card */
  border: 1px solid #ccc; /* Border around the card */
  border-radius: 15px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin-top: 20px; /* Space between image and text */
  text-align: justify;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .content-block {
    flex-direction: column;
    align-items: center;
  }

  .content-image {
    width: 100%;
    max-width: 500px;
  }

  .content-text {
    width: 100%;
  }
  .content-text li {
    font-size: 18px; /* Slightly smaller font for tablet screens */
  }
  .card-container {
    padding: 55px;
  }
}

@media (max-width: 600px) {
  .intro-text {
    font-size: 18px;
  }

  .content-text h2 {
    font-size: 28px;
  }

  .content-text p {
    font-size: 18px;
  }

  .card-container {
    padding: 35px;
  }

  .content-text ul li::before {
    content: "•"; /* Keep the custom bullet point */
    color: #4caf50; /* Maintain the green bullet color */
    display: inline-block;
    width: 1em;
    margin-left: -0.8em; /* Adjust bullet alignment for smaller screens */
  }

  .content-text li {
    font-size: 16px; /* Base font size for larger screens */
  }
}

.content-text ul {
  list-style-type: none; /* Remove default bullet points */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.content-text li {
  padding: 10px 0; /* Add space between list items */
  border-bottom: 1px solid #000; /* Add a light border under each item */
  font-size: 16px; /* Base font size for larger screens */
  line-height: 1.6; /* Line height for better readability */
}

.content-text li:last-child {
  border-bottom: none; /* Remove the border for the last item */
}

.content-text li strong {
  font-weight: 600; /* Make the <strong> tag slightly bolder */
}

.content-text ul li::before {
  content: "•"; /* Add custom bullet point */
  color: #4caf50; /* Green color for bullet point */
  display: inline-block;
  width: 1em;
  margin-left: -1em; /* Align bullets with text */
}
