header {
  background: #fec722;
  overflow: hidden;
  position: relative;
  padding: 30px 60px;
  padding-bottom: 10px;
}
.header_bg {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-image: url(./../../assets/img/index/header_bg.png);
  background-size: 100%;
  background-position: bottom center;
  background-repeat: no-repeat;
}
.header_bg img {
  width: 100%;
  position: relative;
  top: 5px;
}
.header_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 3;
}
.header_container img {
  width: 120px;
}
.header_nav {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.header_container a {
  font-family: "Montserrat", sans-serif;
  color: black;
  font-size: 18px;
  font-weight: 600;
  margin: 10px;
}
.header_nav .headermenu:hover {
  color: #ff156d;
}
.header_nav .headermenu.active {
  color: #ff156d;
}
@media only screen and (max-width: 1200px) {
  .header_bg {
    background: none;
  }
}
@media only screen and (max-width: 768px) {
  header {
    padding: 30px 40px;
  }
}
@media only screen and (max-width: 600px) {
  header {
    padding: 30px 20px;
  }
  .header_bg {
    display: none;
  }
  .header_container img {
    width: 100px;
  }
  .header_nav {
    margin-top: 20px;
  }
  .header_container a {
    font-size: 12px;
    margin: 8px;
  }
}
