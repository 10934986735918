.location .first h1 {
  text-align: center;
  font-size: 48px;
  color: #EC1A6C;
  margin: 60px 0;
  font-family: 'Montserrat', sans-serif;
}





.location .location_container {
  padding: 200px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: cover;
  margin-bottom: 60px;
}
.location .location_container h2 {
  font-size: 76px;
  font-family: 'Montserrat', sans-serif;
  color: white;
  margin-bottom: 20px;
}
.location .location_container button {
  font-size: 20px;
  font-weight: 800;
  color: #EC1A6C;
  background-color: #FFD074;
  padding: 20px 40px;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
}
#location6 {
  background-image: url(./../../assets/img/location/battersea.png);
}
#location7 {
  background-image: url(./../../assets/img/location/canningtown.png);
}
#location3 {
  background-image: url(./../../assets/img/location/hernehill1.png);
}
#location8 {
  background-image: url(./../../assets/img/location/camden.png);
}
#location9 {
  background-image: url(./../../assets/img/location/croydon.png);
}
@media only screen and (max-width:768px) {
  .location .first h1 {
    font-size: 36px;
  }
  .location .location_container h2 {
    font-size: 60px;
  }
}





.location .second {
  width: 100%;
  display: grid;
  grid-template-columns: calc(50% - 20px) 40px calc(50% - 20px);
}
.location .second_1 {
  padding: 200px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: cover;
}
.location .second h3 {
  font-size: 60px;
  font-family: 'Montserrat', sans-serif;
  color: white;
  margin-bottom: 20px;
}
.location .second button {
  font-size: 18px;
  font-weight: 800;
  color: #EC1A6C;
  background-color: #FFD074;
  padding: 20px 40px;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
}
#second1 {
  background-image: url(./../../assets/img/location/foodtruck.png);
}
#second2 {
  background-image: url(./../../assets/img/location/company.png);
}
@media only screen and (max-width: 1300px) {
  .location .second {
    grid-template-columns: 100%;
  }
  .location .second_1 {
    margin-bottom: 60px;
  }
}










/* For Phone together */
@media only screen and (max-width:600px) {
  .location .first h1 {
    font-size: 36px;
    margin: 40px 0;
  }
  .location .location_container {
    height: 400px;
    padding: 20px;
    margin-bottom: 40px;
    text-align: center;
  }
  .location .second_1 h3 {
    font-size: 40px;
  }
  .location .second_1 button {
    font-size: 16px;
    padding: 15px 30px;
  }
  .location .second_1 {
    height: 400px;
    padding: 20px;
    margin-bottom: 40px;
    text-align: center;
  }
  .location .location_container h2 {
    font-size: 40px;
  }
  .location .location_container button {
    font-size: 16px;
    padding: 15px 30px;
  }
}
