@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700&family=Roboto:wght@100;400;500;700&family=Work+Sans:wght@300;400;500;600;700&display=swap');


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins',sans-serif;
}
a {
  text-decoration: none;
  color: inherit;
}
button {
  border: none;
}
h1,h2,h3,h4,h5,h6 {
  font-family: 'Montserrat',sans-serif;
}
