.home .first {
  width: 100%;
  position: relative;
}
.home .first video {
  width: 100%;
}
.home .first_scroll_down {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40%;
  z-index: 99;
}
.home .first_scroll_down button {
  padding: 10px 40px;
  font-size: 20px;
  letter-spacing: 3px;
  background: none;
  border: 2px solid #fec722;
  color: white;
  font-family: "Poppins", sans-serif;
  border-radius: 40px;
  font-weight: 600;
}
.home .first_scroll_down a {
  margin-bottom: 20px;
}
.home .first_scroll_down img {
  height: 40px;
}

@media only screen and (max-width: 768px) {
  .home .first_scroll_down {
    height: 50%;
  }
  .home .first_scroll_down button {
    font-size: 18px;
    letter-spacing: 2px;
  }
  .home .first_scroll_down img {
    height: 30px;
  }
}
@media only screen and (max-width: 600px) {
  .home .first {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 450px;
  }
  .home .first video {
    height: 450px;
    width: auto;
  }
  .home .first_scroll_down {
    height: 60%;
  }
  .home .first_scroll_down button {
    font-size: 14px;
    letter-spacing: 2px;
  }
  .home .first_scroll_down img {
    height: 30px;
  }
}

.home .second {
  width: 100%;
  display: grid;
  grid-template-columns: 48% 52%;
  margin-top: -8px;
}
.home .second_1 {
  padding: 0 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-image: url(./../../assets/img/index/second_1_bg.png);
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 100%;
}
.home .second_1 h2 {
  font-size: 40px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 15px;
}
.home .second_1 h2::before {
  content: "";
  display: block;
  background-image: url(./../../assets/img/index/car.png);
  height: 80px;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: 100px 0;
}
.home .second_1 img {
  width: 150px;
  margin-bottom: 35px;
}
.home .second_1 p {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: 30px;
  width: 80%;
  margin-bottom: 70px;
  color: #3f3f37;
}
.home .second_1 button {
  font-family: "Roboto", sans-serif;
  padding: 20px;
  letter-spacing: 2px;
  text-align: center;
  width: 220px;
  font-size: 14px;
  font-weight: 700;
  color: #ed1e6c;
  border: 2px solid #ed1e6c;
  background: none;
  box-shadow: 0px 21px 61px -11px #ffd074b2;
}
.home .second_2 img {
  width: 100%;
}
@media only screen and (max-width: 1100px) {
  .home .second {
    grid-template-columns: 100%;
  }
  .home .second_1 {
    padding: 220px 60px;
  }
}
@media only screen and (max-width: 768px) {
  .home .second_1 {
    padding: 120px 40px;
  }
}
@media only screen and (max-width: 600px) {
  .home .second_1 {
    padding: 80px 20px;
  }
  .home .second_1 h2 {
    font-size: 30px;
  }
  .home .second_1 img {
    width: 120px;
  }
  .home .second_1 p {
    font-size: 16px;
    width: 90%;
    margin-bottom: 50px;
  }
  .home .second_1 button {
    width: 180px;
    padding: 15px 20px;
  }
  .home .second_1 h2::before {
    height: 60px;
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: 40px 0;
  }
}

.home .third {
  width: 100%;
  display: grid;
  grid-template-columns: 48% 52%;
  margin-top: -8px;
  position: relative;
}
.home .third_1 img {
  width: 100%;
}
.home .third_2 {
  padding: 0 60px;
  padding-left: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-image: url(./../../assets/img/index/third_2_bg.png);
  background-repeat: no-repeat;
  background-position: bottom left;
  background-color: #fec722;
  background-size: 90%;
}
.home .third_2 h2 {
  font-size: 40px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 15px;
}
.home .third_2 img {
  width: 250px;
  margin-bottom: 35px;
}
.home .third_2 p {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: 30px;
  width: 90%;
  margin-bottom: 70px;
  color: #3f3f37;
}
.home .third_2 button {
  font-family: "Roboto", sans-serif;
  padding: 20px;
  letter-spacing: 2px;
  text-align: center;
  width: 220px;
  font-size: 14px;
  font-weight: 700;
  color: #ed1e6c;
  border: 2px solid #ed1e6c;
  background: none;
  box-shadow: 0px 21px 61px -11px #ffd074b2;
}

@media only screen and (max-width: 1100px) {
  .home .third {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
  .home .third_1,
  .third_2 {
    flex: 0 0 100%;
  }
  .home .third_2 {
    padding: 120px 60px;
    padding-left: 40%;
  }
}
@media only screen and (max-width: 768px) {
  .home .third_2 {
    padding-right: 40px;
  }
}
@media only screen and (max-width: 600px) {
  .home .third_2 {
    padding: 80px 20px;
  }
  .home .third_2 h2 {
    font-size: 30px;
  }
  .home .third_2 img {
    width: 200px;
  }
  .home .third_2 p {
    font-size: 16px;
    width: 90%;
    margin-bottom: 50px;
  }
  .home .third_2 button {
    width: 180px;
    padding: 15px 20px;
  }
  .home .third_2 {
    background-image: url(./../../assets/img/index/bg.png);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 100%;
  }
}

.home .fourth {
  width: 100%;
  padding: 230px 0;
  background-image: url(./../../assets/img/index/fourth_bg.png);
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
  position: relative;
  z-index: 9;
  color: white;
}
.home .fourth span {
  font-size: 16px;
  letter-spacing: 3px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.home .fourth h2 {
  font-size: 70px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 40px;
}
.home .fourth a {
  background-color: #ffd074;
  width: 294px;
  font-size: 22px;
  font-weight: 800;
  letter-spacing: 1px;
  color: #ee386c;
  font-family: "Poppins", sans-serif;
  text-align: center;
  padding: 25px 0;
}
@media only screen and (max-width: 768px) {
  .home .fourth span {
    font-size: 14px;
  }
  .home .fourth h2 {
    font-size: 60px;
  }
  .home .fourth a {
    font-size: 20px;
    padding: 20px 0;
  }
}
@media only screen and (max-width: 600px) {
  .home .fourth {
    padding: 150px 0;
  }
  .home .fourth span {
    font-size: 12px;
  }
  .home .fourth h2 {
    font-size: 48px;
  }
  .home .fourth a {
    font-size: 16px;
    padding: 18px 0;
    width: 200px;
  }
}

.home .bg_container1 {
  padding-bottom: 50px;
  background-image: url(./../../assets/img/index/bg.png);
  background-repeat: no-repeat;
  background-position: bottom left;
}
